<template>
  <div>
    <v-container fluid>
      <v-row style="margin-top: 50px" v-if="carregando" dense>
        <v-col cols="12" class="text-center">
          <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
        </v-col>
      </v-row>

      <v-form v-else v-model="valid" ref="form" lazy-validation>
        <v-widget title="Pedido de Compra" :temCampoObrigatorio="true">
          <div slot="widget-content">
            <v-row dense>
              <v-col cols="12" sm="2">
                <v-text-field persistent-placeholder outlined v-model="pedido_compra.numero" name="numero" label="Número" autocomplete="off" readonly></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <campo-data label="Data" persistent-placeholder outlined v-model="pedido_compra.data" :rules="[rules.obrigatorio]" ref="data" @keypress.native.enter="$refs.cliente.focus()"></campo-data>
              </v-col>
              <v-col cols="4" sm="3">
                <buscar-participante label="Buscar Fornecedor" ref="cliente" persistent-placeholder outlined v-model="pedido_compra.participante.cnpjCpf" @participanteSelecionado="adicionarParticipante" :rules="[rules.obrigatorio]" autofocus></buscar-participante>
              </v-col>
              <v-col cols="8" sm="5">
                <v-text-field label="Fornecedor" persistent-placeholder outlined v-model="pedido_compra.participante.razaoSocialNome" readonly ref="razaoSocialNome" @keypress.native.enter="$refs.documento.focus()"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="3">
                <as-campo-valor label="Quantidade" persistent-placeholder outlined v-model="totais.quantidade" :decimais="2" readonly></as-campo-valor>
              </v-col>
              <v-col cols="3">
                <as-campo-valor label="Desconto R$" persistent-placeholder outlined v-model="pedido_compra.desconto" :decimais="2"></as-campo-valor>
              </v-col>
              <v-col cols="3">
                <as-campo-valor label="Frete R$" persistent-placeholder outlined v-model="pedido_compra.frete" :decimais="2"></as-campo-valor>
              </v-col>
              <v-col cols="3">
                <as-campo-valor label="Valor" persistent-placeholder outlined v-model="totais.valor" :decimais="2" readonly></as-campo-valor>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-textarea name="obs" rows="2" label="Observação" persistent-placeholder outlined v-model="pedido_compra.observacao" ref="obs"></v-textarea>
              </v-col>
            </v-row>

            <v-divider class="mt-5 mb-5"></v-divider>

            <v-card>
              <v-toolbar flat dark color="primary" dense>
                <v-toolbar-title>Produtos</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn color="primary" depressed @click="abrirBuscaProdutos">Adicionar Produto</v-btn>
              </v-toolbar>
              <v-card-text>
                <v-data-table :headers="headers" :items="pedido_compra.produtos" hide-default-footer disable-pagination fixed-header height="300px">
                  <template v-slot:item="props">
                    <tr>
                      <td class="text-center">{{ props.item.codigo }}</td>
                      <td class="text-center">{{ props.item.referencia_fabricante }}</td>
                      <td>{{ props.item.descricao }}</td>
                      <td>{{ props.item.cliente }}</td>
                      <td class="text-center">{{ props.item.quantidade | formatarValor }}</td>
                      <td class="text-center">{{ props.item.valor | formatarMoeda }}</td>
                      <td class="text-center">{{ props.item.subtotal | formatarMoeda }}</td>
                      <td class="text-center">
                        <v-menu top right transition="slide-x-transition">
                          <template v-slot:activator="{ on }">
                            <v-icon @click.stop="" v-on="on">more_vert</v-icon>
                          </template>
                          <v-list dense>
                            <v-list-item :key="props.item.produto + 10" @click="editarProduto(props.item)">
                              <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                              <v-list-item-title>Editar</v-list-item-title>
                            </v-list-item>
                            <v-list-item :key="props.item.produto + 15" @click="removerProduto(props.item)">
                              <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                              <v-list-item-title>Remover</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>

            <v-divider class="mt-5 mb-5"></v-divider>

            <v-card v-show="pedido_compra.produtos.length > 0">
              <v-toolbar flat dark color="primary" dense>
                <v-toolbar-title>Duplicatas</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form v-model="valid_duplicata" ref="form_duplicata" lazy-validation>
                  <v-row dense>
                    <v-col cols="3">
                      <as-campo-valor label="Valor" persistent-placeholder outlined v-model="totais.valor" :decimais="2" readonly @keypress.native.enter="$refs.data_parcela.focus()"></as-campo-valor>
                    </v-col>
                    <v-col cols="3">
                      <campo-data label="1ª Parcela" persistent-placeholder outlined v-model="gerador_duplicata.data_parcela" :rules="[rules.obrigatorio]" ref="data_parcela" @keypress.native.enter="$refs.intervalo.focus()"></campo-data>
                    </v-col>
                    <v-col cols="2">
                      <as-campo-valor label="Intervalo" persistent-placeholder outlined v-model="gerador_duplicata.intervalo" :decimais="0" :rules="[rules.obrigatorio, rules.valorMaiorQueZero]" ref="intervalo" @keypress.native.enter="$refs.parcelas.focus()"></as-campo-valor>
                    </v-col>
                    <v-col cols="2">
                      <as-campo-valor label="Parcelas" persistent-placeholder outlined v-model="gerador_duplicata.quantidade_parcelas" :decimais="0" :rules="[rules.obrigatorio, rules.valorMaiorQueZero]" ref="parcelas" @keypress.native.enter="gerarDuplicatas"></as-campo-valor>
                    </v-col>
                    <v-col cols="2">
                      <v-btn color="primary" block @click="gerarDuplicatas" :disabled="!valid_duplicata">Gerar</v-btn>
                    </v-col>
                  </v-row>
                </v-form>
                <v-data-table :headers="headers_duplicatas" :items="pedido_compra.duplicatas" hide-default-footer disable-pagination fixed-header height="300px">
                  <template v-slot:item="props">
                    <tr>
                      <td class="text-center">{{ props.item.documento }}</td>
                      <td class="text-center">{{ props.item.data | formatarData }}</td>
                      <td>{{ props.item.tipo }}</td>
                      <td class="text-center">{{ props.item.valor | formatarMoeda }}</td>
                      <td class="text-center">
                        <v-menu top right transition="slide-x-transition">
                          <template v-slot:activator="{ on }">
                            <v-icon @click.stop="" v-on="on">more_vert</v-icon>
                          </template>
                          <v-list dense>
                            <v-list-item @click="editarDuplicata(props.item)">
                              <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                              <v-list-item-title>Editar</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="excluirDuplicata(props.item)">
                              <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                              <v-list-item-title>Remover</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </div>
          <template v-slot:widget-footer-action>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed class="mr-2" @click="cancelar">Cancelar</v-btn>
              <v-btn depressed color="primary" :loading="salvando" @click="salvar" :disabled="!valid">Salvar</v-btn>
            </v-card-actions>
          </template>
        </v-widget>
      </v-form>
    </v-container>

    <v-dialog v-model="dialogQuantidade" max-width="800px" persistent scrollable>
      <v-card>
        <v-toolbar flat dark color="primary" dense>
          <v-toolbar-title>Quantidade do Produto</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-1">
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field persistent-placeholder outlined v-model="produto.codigo" name="codigo" label="Código" autocomplete="off" readonly></v-text-field>
            </v-col>
            <v-col cols="12" sm="9">
              <v-text-field persistent-placeholder outlined v-model="produto.descricao" name="descricao" label="Descrição" autocomplete="off" readonly></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="2">
              <v-text-field persistent-placeholder outlined v-model="produto.referencia_fabricante" name="referencia_fabricante" label="REF Fabricante" autocomplete="off" @keypress.native.enter="$refs.cliente.focus()"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field persistent-placeholder outlined v-model="produto.cliente" ref="cliente" label="Cliente" autocomplete="off" @keypress.native.enter="$refs.quantidade.focus()"></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Quantidade" persistent-placeholder outlined v-model="produto.quantidade" :rules="[rules.obrigatorio, rules.valorMaiorQueZero]" :decimais="2" ref="quantidade" @blur="calcularSubtotal" @keypress.native.enter="$refs.valor.focus()"></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Valor" persistent-placeholder outlined v-model="produto.valor" :rules="[rules.obrigatorio, rules.valorMaiorQueZero]" :decimais="2" ref="valor" @blur="calcularSubtotal" @keypress.native.enter="salvarProduto"></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="SubTotal" persistent-placeholder outlined v-model="produto.subtotal" :decimais="2" ref="subtotal" readonly @keypress.native.enter="salvarProduto"></as-campo-valor>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-container fluid>
            <v-row dense>
              <v-spacer></v-spacer>
              <v-col class="text-right">
                <v-btn @click="dialogQuantidade = false" text>Cancelar</v-btn>
                <v-btn color="primary" @click="salvarProduto">Salvar</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEditDuplicata" max-width="800px" persistent scrollable>
      <v-card>
        <v-toolbar flat dark color="primary" dense>
          <v-toolbar-title>Editar Duplicata</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text class="pt-1">
          <v-form v-model="valid_duplicata" ref="form_duplicata" lazy-validation>
            <v-row dense>
              <v-col cols="3">
                <v-text-field persistent-placeholder outlined v-model="duplicata.documento" label="Documento" autocomplete="off" @keypress.native.enter="$refs.data.focus()" ref="documento" :rules="[rules.obrigatorio]"></v-text-field>
              </v-col>
              <v-col cols="3">
                <campo-data label="Data Parcela" persistent-placeholder outlined v-model="duplicata.data" :rules="[rules.obrigatorio]" ref="data" @keypress.native.enter="$refs.tipo.focus()"></campo-data>
              </v-col>
              <v-col cols="3">
                <v-select :items="tipos" persistent-placeholder outlined v-model="duplicata.tipo" label="Tipo" ref="tipo" @keypress.native.enter="$refs.valor.focus()" :rules="[rules.obrigatorio]"></v-select>
              </v-col>
              <v-col cols="3">
                <as-campo-valor label="Valor" persistent-placeholder outlined v-model="duplicata.valor" :decimais="2" :rules="[rules.obrigatorio, rules.valorMaiorQueZero]" ref="valor" @keypress.native.enter="atualizarDuplicata"></as-campo-valor>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-container fluid>
              <v-row dense>
                <v-spacer></v-spacer>
                <v-col class="text-right">
                  <v-btn @click="dialogEditDuplicata = false" text>Cancelar</v-btn>
                  <v-btn color="primary" @click="atualizarDuplicata">Salvar</v-btn>
                </v-col>
              </v-row>
            </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <dialog-buscar-produto ref="dialogBuscarProduto" @produtoSelecionado="selecionarProduto"></dialog-buscar-produto>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import { mask } from 'vue-the-mask';
import constantes from '@/util/constantes';
import * as regras from '@/util/validacaoFormulario';
import formatador from '@/util/formatador';
import DialogBuscarProduto from '@/components/dialogs/DialogBuscarProduto';
import { clone } from '@/util/helpers';
import moment from 'moment';

export default {
  name: 'FormularioPedidosCompra',
  components: {
    DialogBuscarProduto,
  },
  data() {
    return {
      valid: true,
      valid_duplicata: true,
      salvando: false,
      rules: { ...regras },
      constantes: constantes,
      produto: {},
      dialogQuantidade: false,
      modoEdicao: false,
      headers: [
        { text: 'Código', value: 'codigo', sortable: false, width: '10%', align: 'center' },
        { text: 'REF Fabricante', value: 'referencia_fabricante', sortable: false, width: '10%' },
        { text: 'Descrição', value: 'descricao', sortable: false, width: '25%' },
        { text: 'Cliente', value: 'cliente', sortable: false, width: '15%' },
        { text: 'Quantidade', value: 'quantidade', sortable: false, width: '5%', align: 'center' },
        { text: 'Valor', value: 'valor', sortable: false, width: '5%', align: 'center' },
        { text: 'Subtotal', value: 'subtotal', sortable: false, width: '5%', align: 'center' },
        { text: 'Ações', value: 'acoes', sortable: false, width: '5%', align: 'center' },
      ],
      gerador_duplicata: {
        data_parcela: '',
        intervalo: 30,
        quantidade_parcelas: 2,
      },
      headers_duplicatas: [
        { text: 'Documento', value: 'documento', sortable: false, width: '10%', align: 'center' },
        { text: 'Data', value: 'data', sortable: false, width: '30%', align: 'center' },
        { text: 'Tipo', value: 'tipo', sortable: false, width: '35%' },
        { text: 'Valor', value: 'valor', sortable: false, width: '20%', align: 'center' },
        { text: 'Ações', value: 'acoes', sortable: false, width: '5%', align: 'center' },
      ],
      duplicata: {},
      dialogEditDuplicata: false,
      tipos: ['Duplicata', 'Boleto', 'Cheque', 'Outros'],
      duplicata_index: -1,
    };
  },
  computed: {
    ...mapState('pedidosCompra', {
      pedido_compra: 'item',
      carregando: 'carregando',
    }),
    totais() {
      let quantidade_total = this.pedido_compra.produtos.reduce((soma, produto) => {
        return soma + produto.quantidade;
      }, 0);

      let valor_produtos = this.pedido_compra.produtos.reduce((soma, produto) => {
        //considerar descontos e frete
        return soma + produto.subtotal;
      }, 0);

      let valor_total = valor_produtos - this.pedido_compra.desconto + this.pedido_compra.frete;

      return {
        quantidade: quantidade_total,
        valor: valor_total,
      };
    },
  },
  methods: {
    cancelar() {
      this.$router.push({ name: 'PedidosCompraLista' });
    },
    async salvar() {
      if (this.pedido_compra.participante._id) {
        if (this.pedido_compra.produtos.length > 0) {
          if (this.pedido_compra._id) {
            await this.$store.dispatch('pedidosCompra/atualizar', this.pedido_compra);
          } else {
            await this.$store.dispatch('pedidosCompra/salvar', this.pedido_compra);
          }
          this.$router.push({ name: 'PedidosCompraLista' });
        } else {
          this.$store.commit('setMensagemErro', 'Adicione pelo menos um produto');
        }
      } else {
        this.$store.commit('setMensagemErro', 'Selecione um fornecedor');
      }
    },
    adicionarParticipante(participante) {
      this.pedido_compra.participante.cnpjCpf = formatador.formatarCnpj(this.pedido_compra.participante.cnpjCpf);
      this.$store.commit('pedidosCompra/SET_PARTICIPANTE', participante);
    },
    abrirBuscaProdutos() {
      this.produto = {};
      this.$refs.dialogBuscarProduto.abrir();
    },
    selecionarProduto(produto_selecionado) {
      this.produto = {
        produto: produto_selecionado._id,
        codigo: produto_selecionado.codigo,
        referencia_fabricante: produto_selecionado.referencia_fabricante,
        descricao: produto_selecionado.descricao,
        cliente: '',
        quantidade: 0,
        valor: produto_selecionado.valor,
        subtotal: 0,
        nota_fiscal: '',
        entrega: '',
        previsao: '',
      };
      this.dialogQuantidade = true;
      setTimeout(() => {
        this.$refs.quantidade.focus();
      }, 100);
    },
    salvarProduto() {
      if (this.modoEdicao) {
        this.$store.commit('pedidosCompra/UPDATE_PRODUTO', this.produto);
        this.modoEdicao = false;
      } else {
        let index = this.pedido_compra.produtos.findIndex((produto) => produto.codigo == this.produto.codigo);
        if (index > -1) {
          this.$store.commit('setMensagemErro', 'Produto já adicionado ao pedido!');
        } else {
          this.$store.commit('pedidosCompra/SET_PRODUTO', this.produto);
        }
      }

      this.$store.commit('pedidosCompra/SET_TOTAIS', this.totais);

      this.dialogQuantidade = false;
    },
    calcularSubtotal() {
      this.produto.subtotal = this.produto.quantidade * this.produto.valor;
    },
    editarProduto(produto_selecionado) {
      this.produto = clone(produto_selecionado);
      this.dialogQuantidade = true;
      setTimeout(() => {
        this.$refs.quantidade.focus();
      }, 100);
      this.modoEdicao = true;
    },
    removerProduto(item) {
      this.$root.$confirmacao.open('Remover', 'Tem certeza que deseja remover este produto ?').then((confirmado) => {
        if (confirmado) {
          this.$store.commit('pedidosCompra/EXCLUIR_PRODUTO', item);

          this.$store.commit('pedidosCompra/SET_TOTAIS', this.totais);

          this.produto = {};
        }
      });
    },
    abrirDialogDuplicatas() {
      this.dialogDuplicatas = true;
    },
    gerarDuplicatas() {
      if (this.$refs.form_duplicata.validate()) {
        this.pedido_compra.duplicatas = [];
        let valor_parcela = this.totais.valor / this.gerador_duplicata.quantidade_parcelas;
        let duplicata1 = {
          data: this.gerador_duplicata.data_parcela,
          tipo: 'Duplicata',
          valor: valor_parcela,
          documento: 'A',
        };
        this.pedido_compra.duplicatas.push(duplicata1);

        let documentos = 'BCDEFGHIJKLMNOPQRSTUVWXYZ';

        let data_parcela = moment(this.gerador_duplicata.data_parcela);

        let qtd_parcelas = this.gerador_duplicata.quantidade_parcelas - 1;
        if (qtd_parcelas > 0) {
          for (let i = 0; i < qtd_parcelas; i++) {
            let nova_duplicata = {
              data: data_parcela.add(this.gerador_duplicata.intervalo, 'days').format('YYYY-MM-DD'),
              tipo: 'Duplicata',
              valor: valor_parcela,
              documento: documentos[i],
            };
            this.pedido_compra.duplicatas.push(nova_duplicata);
            nova_duplicata = {};
          }
        }
      }
    },
    editarDuplicata(duplicata_selecionada) {
      this.duplicata = {};
      this.duplicata_index = this.pedido_compra.duplicatas.findIndex((duplicata) => duplicata.documento == duplicata_selecionada.documento);
      this.duplicata = clone(this.pedido_compra.duplicatas[this.duplicata_index]);
      this.dialogEditDuplicata = true;
      setTimeout(() => {
        this.$refs.documento.focus();
      }, 500);
    },
    atualizarDuplicata() {
      this.pedido_compra.duplicatas.splice(this.duplicata_index, 1, this.duplicata);
      this.dialogEditDuplicata = false;
      this.duplicata_index = -1;
      this.duplicata = {};
    },
    async excluirDuplicata(duplicata_selecionada) {
      this.duplicata_index = this.pedido_compra.duplicatas.findIndex((duplicata) => duplicata.documento == duplicata_selecionada.documento);
      if (this.duplicata_index > -1) {
        let confirmado = await this.$root.$confirmacao.open('Excluir', 'Tem certeza que deseja excluir esta fatura?');
        if (confirmado) {
          this.pedido_compra.duplicatas.splice(this.duplicata_index, 1);
          this.duplicata_index = -1;
        }
      }
    },
  },
};
</script>

<style></style>
